<!DOCTYPE html>

<html lang="en" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="utf-8" />
    <link rel="stylesheet" [href]="stylesheetUrl" />
  </head>

  <body class="referral-form">
    <div class="page">
      <img id="sig" [src]="logoUrl" />

      <table>
        <tr>
          <td>Name:</td>
          <td>{{ memberName }}</td>
        </tr>
        <tr>
          <td>Date of Birth:</td>
          <td>{{ documentDetails.member.dob | date : 'mediumDate' : '+0' }}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{{ phone | phone }}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{{ address$ | async }}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{{ email }}</td>
        </tr>
        <tr>
          <td>Provider Name:</td>
          <td>{{ providerName$ | async }}</td>
        </tr>
        <tr>
          <td>Procedure Type:</td>
          <td>{{ procedureName$ | async }}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{{ documentDetails.comment }}</td>
        </tr>
      </table>

      <div class="details">
        <div class="thankYou">
          Thank you for calling and scheduling this patient.
        </div>
        <div class="pleaseContact">
          If you have any questions, please contact:
        </div>
        <div class="careAdvocateName">{{ careAdvocateName }}</div>
        <div class="careAdvocateTitle">SurgeryPlus Care Advocate</div>
        <div class="hubPhoneNumber">{{ hubPhoneNumber | phone }}</div>
        <div class="hubEmail">{{ hubEmail }}</div>
        <div class="verified">
          Employer Direct Healthcare has verified Patient’s coverage through the
          SurgeryPlus™ benefit and authorizes reimbursement for services
          rendered under the current contractual agreement.
        </div>
      </div>
    </div>
  </body>
</html>
