import { formatDate } from '@angular/common';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { Dictionary } from './dictionary';
import { FileResponse } from './fileResponse';

// left pads the number with 0s for hour and minute display
function d2(num: Number) {
  return num.toString().padStart(2, '0');
}

export interface AnyService {
  dateOfService: Date | string;
  timeOfService?: string;
}

export class Utils {
  static combineServiceTime(val: AnyService, reset: Date | string) {
    if (val.dateOfService instanceof Date) {
      const dateTimeString =
        formatDate(`${val.dateOfService}`, 'shortDate', 'en-US') +
        ' ' +
        val.timeOfService;

      val.dateOfService = new Date(dateTimeString);

      // if the user enters something crazy or we have another bug.
      if (isNaN(val.dateOfService.valueOf())) {
        console.error(
          'invalid date constructed %s, resetting date',
          dateTimeString
        );
        val.dateOfService = reset;
      }
    }
  }

  static extractServiceTime(service: AnyService) {
    const appointment = service.dateOfService
      ? new Date(service.dateOfService)
      : null;

    service.dateOfService = appointment;

    // since all dateTimes have a time component, and this time is optional,
    // ignore appointments set for midnight (12:00:00 AM, time-part = 0 seconds)
    const showTimeOfService = Utils.isValidAppointmentDate(appointment);

    // Have to manually format HH:MM AM/PM string
    // because timepicker does not like seconds...
    service.timeOfService = showTimeOfService
      ? Utils.formatTime(appointment)
      : '';
  }
  static convertUTCToCT(updatedDate: Date | string) {
    return new Date(updatedDate).toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    });
  }
  static isValidAppointmentDate(date: Date): boolean {
    return (
      date instanceof Date &&
      !isNaN(date.valueOf()) &&
      (date.getHours() !== 0 ||
        date.getMinutes() !== 0 ||
        date.getSeconds() !== 0)
    );
  }

  static padLeft(arg: string | number, char: string, len: number) {
    const str = arg.toString();

    if (str.length < len) {
      for (let i = 0; i < len - str.length; i++) {}
    }
  }

  static formatTime(date: Date) {
    const hh = date.getHours();
    const mm = date.getMinutes();

    return d2(hh) + ':' + d2(mm);
  }

  /** clears the text selection on page */
  static clearSelection() {
    const doc = document as any;
    if (doc.selection && doc.selection.empty) {
      doc.selection.empty();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
    }
  }
  /**
   * checks date for validity. valid if not null, instance of the date class, and resolves to a real number
   * @param date the date
   */
  static isDateValid(date: Date) {
    return date != null && date instanceof Date && !isNaN(date.getTime());
  }
  /**
   * formats this date-time as an ISO 8601 RFC 3339 compliant date time string, including local offset
   * @param date the date time to format
   */
  static formatLocalOffset(date: Date): string {
    // from: https://stackoverflow.com/a/17415677/2932782
    if (!this.isDateValid(date)) {
      return null;
    }
    const tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-';
    let pad = function (num: number) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  }

  static getHttpParams(queryParams: any) {
    let params: HttpParams = null;
    if (queryParams) {
      params = new HttpParams();
      Object.keys(queryParams).forEach(function (key) {
        const value = (<any>queryParams)[key];
        if (value && value.getFullYear) {
          const date = value as Date;
          params = params.append(key, date.toISOString());
        } else {
          params = params.append(key, value);
        }
      });
    }
    return params;
  }

  /** opens a file from an http response as a download. Used in the code gen */
  static asFileResult(blob: HttpResponse<Blob>): FileResponse {
    return {
      contentDisposition: blob.headers.get('Content-Disposition'),
      contents: blob.body,
    };
  }
  static openFile(file: FileResponse) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; |$)/;
    const asciiFilenameRegex = /filename=(["'])(.*?[^\\])\1(?:; |$)/;

    let fileName: string = null;
    if (utf8FilenameRegex.test(file.contentDisposition)) {
      fileName = decodeURIComponent(
        utf8FilenameRegex.exec(file.contentDisposition)[1]
      );
    } else {
      const matches = asciiFilenameRegex.exec(file.contentDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file.contents, fileName);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(file.contents);

    var link = document.createElement('a');
    link.href = data;
    if (fileName) {
      link.download = fileName;
    }
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  //TODO: all callers make sure your control actually ends up being required,
  // idk why it isnt doing that
  static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
  static isLocal(): boolean {
    return document.baseURI.toLowerCase().indexOf('/localhost') > -1;
  }

  /** quick map for digits from their string to their numeric representation  */
  private static _charMap: Dictionary<number> = {
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
  };
  /** validates an npi checksum.
   * this helps catch transposition and mistypes
   * this does not grantee the npi exists or was entered correctly
   * see: https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf */
  static validateNpi(npi: string): { valid: boolean; errors: string[] } {
    if (
      !npi ||
      (npi.length != 10 && npi.length != 15) ||
      !/^[\d]+$/.test(npi)
    ) {
      return { valid: false, errors: ['NPI must be a 10 or 15 digit number'] };
    }
    if (npi.length == 10) {
      // use default care issuer number
      npi = '80840' + npi;
    }
    const checkDigitSum = npi
      .slice(0, 14)
      .split('')
      .map((x) => this._charMap[x])
      .map((x, idx) => (idx % 2 === 0 ? x : 2 * x))
      .flatMap((x) => x.toString().split(''))
      .map((x) => this._charMap[x])
      .reduce((total, next) => total + next, 0);
    const checkDigit = 10 - ((checkDigitSum % 10) % 10);

    return this._charMap[npi[14]] == checkDigit
      ? { valid: true, errors: [] }
      : {
          valid: false,
          errors: ['Npi checksum invalid: please verify the entered value'],
        };
  }
  /**
   * This will output Date/Time strings to the format of:
   *    Today, 10:00 AM (if date passed is the same day as today),
   * otherwise it will output Date/Time string to the format of:
   *    1/19/2022, 10:00 AM
   * @param dateString Date/Time object which needs to be converted
   * @returns a string representation of the Date/Time object
   *  depending on how recent the date is
   */
  static transformRecentDateTime(dateString: string | Date): string {
    var dateToTransform = new Date(dateString);

    // if date is today
    var startOfDay = Utils.getStartOfDay(new Date());
    if (startOfDay.valueOf() <= dateToTransform.valueOf()) {
      return (
        'Today, ' +
        dateToTransform.toLocaleString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })
      );
    }

    return dateToTransform.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  }
  /**
   * This will return the passed Date sans hours, minutes, seconds, and milliseconds.
   * @param dateToTruncate Date object which will be truncated of its hours, minutes, seconds, and milliseconds
   * @returns A Date that represents the start of the Date value passed into the function.
   */
  static getStartOfDay(dateToTruncate: Date): Date {
    var hours = 0,
      minutes = 0,
      seconds = 0,
      milliseconds = 0;
    dateToTruncate?.setHours(hours, minutes, seconds, milliseconds);
    return dateToTruncate;
  }
  /**
   * This will check whether the passed string is not null and does not consist entirely of whitespace.
   * @param toCheck string which will be checked
   * @returns true iff the passed string is not null and does not consist entirely of whitespace, false otherwise.
   */
  static notNullOrWhitespace(toCheck: string): boolean {
    return toCheck && toCheck.trim() != '';
  }
}
