import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnesthesiologistsApiService } from 'carehub-api/anesthesiologists-api.service';
import { Anesthesiologist } from 'carehub-api/models/anesthesiologist/anesthesiologist';
import { Guid } from 'carehub-shared/guid';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './anesthesiologist.component.html',
  styleUrls: ['./anesthesiologist.component.scss'],
})
export class AnesthesiologistComponent implements OnInit {
  anesthesiologist$: Observable<Anesthesiologist>;

  menuItems: { displayName: string; routeName: string }[] = [
    {
      displayName: 'DETAILS',
      routeName: 'details',
    },
    {
      displayName: 'DOCUMENTS',
      routeName: 'documents',
    },
    {
      displayName: 'FACILITIES',
      routeName: 'facilities',
    },
    {
      displayName: 'CONTRACTS',
      routeName: 'contracts',
    },
    {
      displayName: 'CONTACTS',
      routeName: 'contacts',
    },
    {
      displayName: 'HISTORY',
      routeName: 'history',
    },
  ];

  constructor(
    public route: ActivatedRoute,
    private service: AnesthesiologistsApiService
  ) {}

  ngOnInit() {
    var anesthesiologistId = this.route.snapshot.params.id;
    if (anesthesiologistId && anesthesiologistId !== Guid.empty) {
      this.anesthesiologist$ =
        this.service.getAnesthesiologistById(anesthesiologistId);
    }
  }
}
