import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacilitiesApiService } from 'carehub-api/facilities-api.service';
import { Facility } from 'carehub-api/models/facility/facility';
import { Guid } from 'carehub-shared/guid';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
})
export class FacilityComponent implements OnInit {
  facility$: Observable<Facility>;

  menuItems: { displayName: string; routeName: string }[] = [
    {
      displayName: 'DETAILS',
      routeName: 'details',
    },
    {
      displayName: 'DOCUMENTS',
      routeName: 'documents',
    },
    {
      displayName: 'PROCEDURES',
      routeName: 'procedures',
    },
    {
      displayName: 'PHYSICIANS',
      routeName: 'providers',
    },
    {
      displayName: 'ANESTHESIOLOGISTS',
      routeName: 'anesthesiologists',
    },
    {
      displayName: 'CONTRACTS',
      routeName: 'contracts',
    },
    {
      displayName: 'CONTACTS',
      routeName: 'contacts',
    },
    {
      displayName: 'HISTORY',
      routeName: 'history',
    },
  ];

  constructor(
    private service: FacilitiesApiService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    var facilityId = this.route.snapshot.params.id;
    if (facilityId && facilityId !== Guid.empty) {
      this.facility$ = this.service.getFacilityById(facilityId);
    }
  }
}
