<div class="member-validation-info">
  <div class="member-info-panel">
    <div class="member-name">
      <div class="memberFullName">
        <label class="member-detail-label"> Full Name: </label>
        <span class="fullName">
          {{
            memberToPreview?.firstName != null ? memberToPreview.firstName : ''
          }}
          {{
            memberToPreview?.middleName != null
              ? ' ' + memberToPreview?.middleName
              : ''
          }}
          {{
            memberToPreview?.lastName != null
              ? ' ' + memberToPreview?.lastName
              : ''
          }}
        </span>
      </div>
      <div class="memberPreferredName">
        <label class="member-detail-label"> Preferred Name: </label>
        <span class="preferredName">
          {{
            memberToPreview?.preferredName != null
              ? memberToPreview.preferredName
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberDob">
        <label class="member-detail-label"> DOB: </label>
        <span class="dob">
          {{
            memberToPreview?.dob != null
              ? datePipe.transform(memberToPreview.dob) +
                ' (' +
                (memberToPreview.dob | date : 'MM/dd/yyyy' : '+0') +
                ')'
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberSsn">
        <label class="member-detail-label"> SSN: </label>
        <span class="ssn" *ngIf="canRevealSsn; else readonlySsn">
          <a href="javascript:void(0)" (click)="showSsn()">{{
            memberToPreviewSsn != null ? memberToPreviewSsn : '(N/A)'
          }}</a>
        </span>
        <ng-template #readonlySsn>
          {{ memberToPreviewSsn != null ? memberToPreviewSsn : '(N/A)' }}
        </ng-template>
      </div>
      <div class="memberGender">
        <label class="member-detail-label"> Gender: </label>
        <span class="gender">
          {{
            memberToPreview?.gender != null ? memberToPreview.gender : '(N/A)'
          }}
        </span>
      </div>
    </div>
    <div class="member-ids">
      <div class="memberClientName">
        <label class="member-detail-label"> Client: </label>
        <span class="clientName">
          {{
            memberToPreview?.client?.name != null
              ? memberToPreview?.client.name
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberSurgeryPlusId">
        <label class="member-detail-label"> Member ID: </label>
        <span class="surgeryPlusMemberId">
          {{
            memberToPreview?.surgeryPlusMemberId != null
              ? memberToPreview?.surgeryPlusMemberId
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberEmployeeNumber">
        <label class="member-detail-label"> Employee Number: </label>
        <span class="surgeryPlusMemberId">
          {{
            memberToPreview?.employeeNumber != null
              ? memberToPreview?.employeeNumber
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberStatus">
        <label class="member-detail-label"> Status: </label>
        <span class="status">
          {{
            memberToPreview?.statusId != null
              ? (memberToPreview?.statusId | lookup : 'MemberStatuses' | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPriority">
        <label class="member-detail-label"> Priority: </label>
        <span
          class="priority-vip"
          *ngIf="
            memberToPreview?.isVip != null && memberToPreview?.isVip;
            else notVip
          "
        >
          VIP
        </span>
        <ng-template #notVip>
          <span class="priority"> Standard </span>
        </ng-template>
      </div>
    </div>
    <div class="plan-overview" [ngClass]="{ 'hide-me': !showMemberBenefits }">
      <div class="memberPlanCarrierMemberNumber">
        <label class="member-detail-label"> Subscriber/Member ID: </label>
        <span class="carrierMemberNumber">
          {{
            memberPlanToPreview?.memberPlanCoverage != null &&
            memberPlanToPreview?.memberPlanCoverage?.carrierMemberNumber != null
              ? memberPlanToPreview?.memberPlanCoverage?.carrierMemberNumber
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPlanName">
        <label class="member-detail-label"> Plan Name: </label>
        <span class="planName">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.name != null
              ? memberPlanToPreview?.plan?.name
              : '(N/A)'
          }}
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.friendlyName != null
              ? ' | ' + memberPlanToPreview?.plan?.friendlyName
              : ''
          }}
        </span>
      </div>
      <div class="memberPlanType">
        <label class="member-detail-label"> Plan Type: </label>
        <span class="planType">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.planTypeId != null
              ? (memberPlanToPreview?.plan?.planTypeId
                | lookup : 'PlanTypes'
                | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPlanSpan">
        <label class="member-detail-label"> Plan Span: </label>
        <span class="planSpan">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.planYearStart != null &&
            memberPlanToPreview?.plan?.planYearEnd != null
              ? (memberPlanToPreview?.plan?.planYearStart
                  | date : 'MM/yyyy' : '+0') +
                ' | ' +
                (memberPlanToPreview?.plan?.planYearEnd
                  | date : 'MM/yyyy' : '+0')
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberAccumulatorYear">
        <label class="member-detail-label"> Accumulator Span: </label>
        <span class="accumulatorYear">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.accumulatorYearStart != null &&
            memberPlanToPreview?.plan?.accumulatorYearEnd != null
              ? (memberPlanToPreview?.plan?.accumulatorYearStart
                  | date : 'MM/yyyy' : '+0') +
                ' | ' +
                (memberPlanToPreview?.plan?.accumulatorYearEnd
                  | date : 'MM/yyyy' : '+0')
              : '(N/A)'
          }}
        </span>
      </div>
    </div>
    <div class="member-contact">
      <div class="memberPhone">
        <label class="member-detail-label"> Phone: </label>
        <span class="phone">
          {{
            memberToPreview?.demographic?.phone != null
              ? phonePipe.transform(memberToPreview?.demographic.phone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberCellPhone">
        <label class="member-detail-label"> Cell Phone: </label>
        <span class="cellPhone">
          {{
            memberToPreview?.demographic?.cellPhone != null
              ? phonePipe.transform(memberToPreview?.demographic.cellPhone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberOtherPhone">
        <label class="member-detail-label"> Other Phone: </label>
        <span class="otherPhone">
          {{
            memberToPreview?.demographic?.otherPhone != null
              ? phonePipe.transform(memberToPreview?.demographic.otherPhone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberEmail">
        <label class="member-detail-label"> Email: </label>
        <span class="email">
          {{
            memberToPreview?.demographic?.email != null
              ? memberToPreview?.demographic.email
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberLanguage">
        <label class="member-detail-label"> Preferred Language: </label>
        <span class="language">
          {{
            memberToPreview?.languageId != null
              ? (memberToPreview?.languageId | lookup : 'Languages' | async)
              : '(N/A)'
          }}
        </span>
      </div>
    </div>
    <div class="member-demographics">
      <div class="memberDemographicType">
        <label class="member-detail-label"> Demographic Type: </label>
        <span class="demographicType">
          {{
            memberToPreview?.demographic?.demographicTypeId != null
              ? (memberToPreview?.demographic?.demographicTypeId
                | lookup : 'DemographicTypes'
                | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberAddress">
        <label class="member-detail-label"> Address: </label>
        <br />
        <span class="address">
          {{
            memberToPreview?.demographic?.address1 != null
              ? memberToPreview?.demographic.address1
              : ''
          }}
        </span>
        <br *ngIf="memberToPreview?.demographic?.address2 != null" />
        <span class="address">
          {{
            memberToPreview?.demographic?.address2 != null
              ? memberToPreview?.demographic.address2
              : ''
          }}
        </span>
        <br />
        <span class="address">
          {{
            memberToPreview?.demographic?.city != null
              ? memberToPreview?.demographic.city + ','
              : ''
          }}
          {{
            memberToPreview?.demographic?.stateId != null
              ? (memberToPreview?.demographic.stateId
                | lookup : 'States'
                | async)
              : ''
          }}
          {{
            memberToPreview?.demographic?.zip != null
              ? memberToPreview?.demographic.zip
              : ''
          }}
          {{
            memberToPreview?.demographic?.plus4 != null
              ? '-' + memberToPreview?.demographic.plus4
              : ''
          }}
        </span>
      </div>
    </div>
    <div class="plan-details" [ngClass]="{ 'hide-me': !showMemberBenefits }">
      <div class="memberRelationshipType">
        <label class="member-detail-label"> Relationship to Member: </label>
        <span class="relationship">
          {{
            memberToPreview?.relationshipTypeId != null
              ? (memberToPreview?.relationshipTypeId
                | lookup : 'RelationshipTypes'
                | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberCoverageLevel">
        <label class="member-detail-label"> Coverage Level: </label>
        <span class="coverageLevel">
          {{
            memberPlanToPreview?.planCoverage != null &&
            memberPlanToPreview?.planCoverage?.coverageTypeId != null
              ? (memberPlanToPreview?.planCoverage?.coverageTypeId
                | lookup : 'CoverageTypes'
                | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPlanAdministrator">
        <label class="member-detail-label"> Administrator: </label>
        <span class="planAdministrator">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.administrator != null
              ? memberPlanToPreview?.plan?.administrator
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPlanNetwork">
        <label class="member-detail-label"> Network: </label>
        <span class="planNetwork">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.network != null
              ? memberPlanToPreview?.plan?.network
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPlanSubNetwork">
        <label class="member-detail-label"> Sub-Network: </label>
        <span class="planSubNetwork">
          {{
            memberPlanToPreview?.plan != null &&
            memberPlanToPreview?.plan?.subNetwork != null
              ? memberPlanToPreview?.plan?.subNetwork
              : '(N/A)'
          }}
        </span>
      </div>
    </div>
  </div>
  <button
    class="validate-member"
    mat-raised-button
    (click)="selectedMemberForValidation(memberToPreview)"
  >
    Confirm
  </button>
</div>
