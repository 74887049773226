import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  getFilteredMembers_Filter,
  MembersApiService,
} from 'carehub-api/members-api.service';
import { Member } from 'carehub-api/models/member/member';
import { MemberByPhone } from 'carehub-api/models/member/memberbyphone';
import { MemberPlan } from 'carehub-api/models/member/memberplan';
import { ColumnDetails } from 'carehub-root/shared/components/controls/smartlist-grid/column-details';
import { LookupService } from 'carehub-root/shared/services/lookup.service';
import { LookupTypes } from 'carehub-root/shared/services/models/lookup-types.enum';
import * as fromRoot from 'carehub-root/state/app.state';
import { FilterableComponent } from 'carehub-shared/components/filterable-component';
import { PhonePipe } from 'carehub-shared/pipes/phone.pipe';
import { SsnPipe } from 'carehub-shared/pipes/ssn.pipe';
import { SmartListCriteria } from 'carehub-shared/smartlist';
import { getCurrentUser } from 'carehub-shared/state';
import { LookupItem, User } from 'carehub-shared/state/shared.reducer';
import { Observable, of } from 'rxjs';

export interface MemberAndPlan {
  member: Member;
  memberPlan: MemberPlan;
}
@Component({
  selector: 'ch-member-selection',
  templateUrl: './member-selection.component.html',
  styleUrls: ['./member-selection.component.scss'],
  providers: [PhonePipe, SsnPipe, DatePipe],
})
export class MemberSelectionComponent
  extends FilterableComponent<getFilteredMembers_Filter, Member>
  implements OnInit
{
  private _phoneNumber: string = '';

  expandedMember: Member;
  expandedMemberSsn: string;
  expandedMemberPlans$: Observable<MemberPlan[]>;
  expandedMemberPlan: MemberPlan;

  @Output() memberAndPlanSelected = new EventEmitter<MemberAndPlan>();

  @Input() set phoneNumber(phoneNum: string) {
    this._phoneNumber = phoneNum;
    this.filter.memberPhoneNumber = phoneNum;
    this.loadData();
  }
  get phoneNumber() {
    return this._phoneNumber;
  }

  memberBirthDate: Date | string;

  gridTitle = 'Members';
  memberLinkIconSet = [
    {
      '1': {
        icon: 'person',
        color: '#000000',
        toolTip: 'Go to Member',
        click: this.onMemberLinkClick.bind(this),
      },
    },
  ];
  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'phone',
      sortable: false,
      header: 'Phone',
      width: 'auto',
      cell: (row: MemberByPhone) =>
        row &&
        this.phonePipe.transform(row.phone || row.cellPhone || row.otherPhone),
    },
    {
      columnDef: 'memberFirstName',
      sortable: false,
      header: 'First Name',
      width: 'auto',
      cell: (row: MemberByPhone) =>
        row.memberFirstName ? `${row.memberFirstName}` : '',
    },
    {
      columnDef: 'memberLastName',
      sortable: false,
      header: 'Last Name',
      width: 'auto',
      cell: (row: MemberByPhone) =>
        row.memberLastName ? `${row.memberLastName}` : '',
    },
    {
      columnDef: 'memberBirthdate',
      header: 'DOB',
      sortable: false,
      width: 'auto',
      cell: (row: MemberByPhone) =>
        row.memberBirthdate
          ? formatDate(`${row.memberBirthdate}`, 'mediumDate', 'en-US', '+0')
          : '',
    },
    {
      columnDef: 'memberStatusId',
      header: 'Status',
      width: 'auto',
      cell: (row: MemberByPhone) =>
        row.memberStatusId
          ? this.lookupService.getDescriptionByTypeAndId(
              LookupTypes.MemberStatuses,
              row.memberStatusId
            )
          : '',
    },
    {
      columnDef: 'memberEmployeeNumber',
      width: 'auto',
      header: 'Emp. #',
      cell: (row: MemberByPhone) =>
        row.memberEmployeeNumber ? `${row.memberEmployeeNumber}` : '',
    },
    {
      columnDef: 'memberLink',
      width: 'auto',
      header: '',
      cell: () => {
        return { icons: [1] };
      },
      cssTextClassResolver: () => {
        return 'go-to-member';
      },
      iconSets: this.memberLinkIconSet,
    },
  ];
  statusDisplay = (item: LookupItem): string => item.description;

  @ViewChild('statusFilter', { static: true }) statusFilter: ElementRef;
  filteredStatuses$: Observable<LookupItem[]>;
  statuses: LookupItem[];
  private user: User;

  constructor(
    private lookupService: LookupService,
    private router: Router,
    private sharedStore: Store<fromRoot.State>,
    private membersApiService: MembersApiService,
    public phonePipe: PhonePipe,
    public ssnPipe: SsnPipe,
    public datePipe: DatePipe
  ) {
    super({ pageSize: 10, sortField: 'memberLastName', sortDirection: 'asc' });
    this.sharedStore.pipe(select(getCurrentUser)).subscribe((user) => {
      this.user = user;
    });
    this.lookupService
      .getAllByType(LookupTypes.MemberStatuses)
      .subscribe((data) => {
        this.statuses = data;
      });
  }
  filterMethod(criteria: SmartListCriteria, filter: null) {
    if (this.phoneNumber && this.phoneNumber != '')
      return this.membersApiService.getMembersByPhoneNumber(criteria, filter);
    return of(null);
  }
  onRowClick(event: Member) {
    this.expandedMember = event;
    this.expandedMemberPlans$ = this.membersApiService.getMemberPlans(
      this.expandedMember?.memberId
    );
    this.expandedMemberPlans$.subscribe((memberPlans) => {
      this.expandedMemberPlan = memberPlans[0];
      this.memberAndPlanSelected.emit({
        member: event,
        memberPlan: this.expandedMemberPlan,
      });
    });
  }
  onMemberLinkClick(event: MemberByPhone) {
    if (event.memberId != null) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          'member-services-mgmt',
          'members',
          event.memberId,
        ])
      );
      window.open(url, '_blank');
    }
  }
}
