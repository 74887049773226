import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Provider } from 'carehub-api/models/provider/provider';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';

@Component({
  selector: 'ch-provider-banner',
  templateUrl: './provider-banner.component.html',
  styleUrls: ['./provider-banner.component.scss'],
})
export class ProviderBannerComponent implements OnInit {
  @Input() provider: Provider;
  constructor(private lookupService: LookupService, private router: Router) {}

  ngOnInit() {}

  get providerStatus() {
    return this.lookupService.getDescriptionByTypeAndId(
      LookupTypes.ProviderStatuses,
      this.provider?.statusId
    );
  }

  goBack(): any {
    if (location.href.indexOf('/provider-network-mgmt/') > -1) {
      this.router.navigateByUrl('provider-network-mgmt/providers');
    } else {
      this.router.navigateByUrl('member-services-mgmt/providers');
    }
  }
}
