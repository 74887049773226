import { Component, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { CallsApiService } from 'carehub-api/calls-api.service';
import { MembersApiService } from 'carehub-api/members-api.service';
import { Call, initializeNewCall } from 'carehub-api/models/communication/call';
import { Member } from 'carehub-api/models/member/member';
import { MemberPlan } from 'carehub-api/models/member/memberplan';
import { MemberCase } from 'carehub-api/models/membercase/membercase';
import { Direction } from 'carehub-rest-services/chiliSauceApi/enums/communication/direction';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-shared/directives/if-allowed.directive';
import { Guid } from 'carehub-shared/guid';
import { PhonePipe } from 'carehub-shared/pipes/phone.pipe';
import { SsnPipe } from 'carehub-shared/pipes/ssn.pipe';
import { AgentEvent } from 'carehub-shared/services/models/telephony/AgentEvent';
import { EventTypes } from 'carehub-shared/services/models/telephony/EventTypes';
import { WindowEventConsumerService } from 'carehub-shared/services/window-event-consumer.service';
import * as fromShared from 'carehub-shared/state';
import * as fromSharedActions from 'carehub-shared/state/shared.actions';
import { User } from 'carehub-shared/state/shared.reducer';
import { Subject, timer } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { MemberAndPlan } from '../member-selection/member-selection.component';
import { ValidationData } from './validation-data';

export enum CallStatus {
  AwaitingCall = 'AwaitingCall',
  Inbound = 'Inbound',
}
export enum ValidationStatus {
  AwaitingCall = 'AwaitingCall',
  NeedsValidation = 'NeedsValidation',
  MemberChosen = 'MemberChosen',
  CaseChosen = 'CaseChosen',
}

function allowOrigin(origin: string) {
  return (
    origin === MAX_ORIGIN ||
    /^https:\/\/.*.edhc.com$/i.test(origin) ||
    /^http(s?):\/\/localhost:4200$/i.test(origin)
  );
}

const MAX_ISSUER = 'MAX';
const MAX_ORIGIN = 'https://max.niceincontact.com';

@Component({
  templateUrl: './call-validation.component.html',
  styleUrls: ['./call-validation.component.scss'],
  providers: [PhonePipe, SsnPipe],
})
export class CallValidationComponent extends BaseComponent {
  private destroyed$ = new Subject<void>();

  public _validationData: Partial<ValidationData> = {
    callStatus: CallStatus.AwaitingCall,
    validationStatus: ValidationStatus.AwaitingCall,
  };
  public isLoggedIn = false;
  public user: User;

  public phoneNumber: string;
  public phoneNumberFormatted: string;
  public selectedMemberPhoneNumberFormatted: string;

  selectedMember: Member | null;
  selectedMemberPlan: MemberPlan | null;
  confirmedMember: Member | null;
  selectedCase: MemberCase | null;

  call: Call = initializeNewCall();

  @ViewChild('memberPanel', { static: true })
  private memberPanel: MatExpansionPanel;
  @ViewChild('memberPreviewPanel', { static: true })
  private memberPreviewPanel: MatExpansionPanel;
  @ViewChild('casePanel', { static: true })
  private casePanel: MatExpansionPanel;

  get hasCallValidationPermission(): Boolean {
    return (
      this.user &&
      checkPermission('CallValidation', PermissionScopes.READ, this.user)
    );
  }
  get hasContextSwapPermission(): Boolean {
    return (
      this.user &&
      checkPermission('ContextSwap', PermissionScopes.READ, this.user)
    );
  }
  get isAwaitingCall(): Boolean {
    return (
      this._validationData &&
      (this._validationData.callStatus === CallStatus.AwaitingCall ||
        this._validationData.validationStatus === ValidationStatus.AwaitingCall)
    );
  }

  constructor(
    private sharedStore: Store<fromRoot.State>,
    protected router: Router,
    private windowEventConsumerService: WindowEventConsumerService,
    private callsApiService: CallsApiService,
    private membersApiService: MembersApiService,
    private phonePipe: PhonePipe,
    public ssnPipe: SsnPipe
  ) {
    super();
    this.sharedStore.dispatch(
      new fromSharedActions.SetHeaderOptions({
        iconNavigationLink: 'call-validation',
        shouldShowHelp: false,
        shouldShowImpersonator: false,
        shouldShowModules: false,
        shouldShowNotifications: false,
        shouldShowSearch: false,
      })
    );
  }
  ngOnInit() {
    this.sharedStore
      .pipe(select(fromShared.getCurrentUser), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.user = result;
        this.isLoggedIn = !!result;
      });

    this.windowEventConsumerService.events$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          (x) =>
            x.data.issuer === MAX_ISSUER &&
            x.data.events &&
            allowOrigin(x.origin)
        ),
        mergeMap((x) => x.data.events as AgentEvent[]),
        filter((x) => x.Type != null)
      )
      .subscribe((event) => this.processEvent(event));

    // other subscription types: "all" | "agent" | "contact" | "contacts"
    if (
      this.windowEventConsumerService.hostOrigin !== null &&
      !allowOrigin(this.windowEventConsumerService.hostOrigin)
    ) {
      console.error(
        `The host origin ${this.windowEventConsumerService.hostOrigin} is not on the allowed origin's list`
      );
    } else {
      try {
        this.windowEventConsumerService.postMessage(
          {
            issuer: 'CareHub',
            messageType: 'RegisterForClientEvents',
            subscriptionTypes: ['all'],
          },
          this.windowEventConsumerService.hostOrigin
        );
      } catch (e) {
        console.error(e);
      }
    }
  }
  onClickGoToMemberCase(): void {
    if (this.selectedMember && this.selectedCase) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          'member-services-mgmt',
          'members',
          this.selectedMember.memberId,
          'cases',
          this.selectedCase.memberCaseId,
        ])
      );

      window.open(url, '_blank');
    }
  }

  onClickGoToMember(): void {
    if (this._validationData.member && this.selectedMember) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          'member-services-mgmt',
          'members',
          this.selectedMember.memberId,
        ])
      );

      window.open(url, '_blank');
    }
  }
  processEvent(res: AgentEvent) {
    switch (res.Type) {
      case EventTypes.CallContactEvent:
        if (res.IsInbound == 'True' && res.Status != 'Disconnected') {
          if ((this.call && !res.ANI) || this.call.contactId != Guid.empty) {
            break;
          }
          this.clearValidationState();

          this.phoneNumber = res.ANI;
          this.phoneNumberFormatted = this.phonePipe.transform(
            this.phoneNumber
          );

          this.call.createdUserId = this.user.userGuid;
          this.call.updatedUserId = this.user.userGuid;
          this.call.callPartyPhone = res.ANI;
          this.call.contactId = res.ContactID;
          this.call.directionId = Direction.inbound;
          this.call.memberId = null;
          this.call.memberCaseId = null;

          // API call to add (or update if exists) "Call" to DB.
          this.callsApiService.updateCall(this.call).subscribe(
            (res) => {
              // Update our call so as to have the new callId for further updates
              this.call = res;
              // Update local component state information.
              this._validationData.phoneNumber = res.callPartyPhone;
              this._validationData.contactId = res.contactId;
              this._validationData.callStatus = CallStatus.Inbound;
              this._validationData.validationStatus =
                ValidationStatus.NeedsValidation;
              this.openAccordionByCallState();
            },
            (error) => {
              this.sharedStore.dispatch(
                new fromSharedActions.SetCurrentError(error)
              );
            }
          );
        }
        if (res.Status == 'Disconnected') {
          if (this.call && this.call.contactId == Guid.empty) {
            break;
          }
          // API call to update "Call" in DB.
          this.callsApiService.updateCall(this.call).subscribe(
            (res) => {
              // IFF it succeeds, then we clear local component state.
              this.clearValidationState();
              this._validationData.callStatus = CallStatus.AwaitingCall;
              this._validationData.validationStatus =
                ValidationStatus.AwaitingCall;
              this.openAccordionByCallState();
            },
            (error) => {
              this.sharedStore.dispatch(
                new fromSharedActions.SetCurrentError(error)
              );
            }
          );
        }
        break;

      case EventTypes.AgentLeg:
        break;

      case EventTypes.AgentError:
        this.sharedStore.dispatch(
          new fromSharedActions.SetCurrentError(res.ResultCode)
        );
        break;

      default:
        break;
    }
  }
  private clearValidationState(softReset?: boolean) {
    this.selectedCase = null;
    this.selectedMember = null;
    this.selectedMemberPlan = null;
    this.selectedMemberPhoneNumberFormatted = null;
    this.confirmedMember = null;
    this.phoneNumberFormatted = null;

    if (softReset) {
      return;
    }

    this.call = initializeNewCall();
    this.call.contactId = Guid.empty;
    this._validationData.member = null;
    this._validationData.memberCase = null;
    this._validationData.phoneNumber = null;
    this._validationData.contactId = null;
    this.phoneNumber = null;
  }

  onSelectedMemberPlanChange(memberAndPlan: MemberAndPlan | null) {
    this.selectedMemberPlan = memberAndPlan?.memberPlan;

    if (memberAndPlan && memberAndPlan.member) {
      var memberT$ = this.membersApiService.getMemberById(
        memberAndPlan.member.memberId,
        { includeChildren: true }
      );
      memberT$.subscribe((member) => {
        this.onSelectedMemberChange(member);
      });
    }
  }

  onSelectedMemberChange(member: Member | null) {
    // clear possibly old case and member selection when a new member is selected
    this.confirmedMember = null;
    this.selectedCase = null;
    this.onSelectedCaseChange(null);

    timer() // timer for expression changed after checked errors
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.selectedMember = member;
        this._validationData.member = member;
        this._validationData.validationStatus = member
          ? ValidationStatus.MemberChosen
          : ValidationStatus.NeedsValidation;

        this.selectedMemberPhoneNumberFormatted = member?.demographic?.phone
          ? this.phonePipe.transform(member.demographic.phone)
          : member?.demographic?.cellPhone
          ? this.phonePipe.transform(member.demographic.cellPhone)
          : member?.demographic?.otherPhone
          ? this.phonePipe.transform(member.demographic.otherPhone)
          : '';
      });
  }
  onMemberConfirmation(member: Member | null) {
    timer() // timer for expression changed after checked errors
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.confirmedMember = member;
        this._validationData.member = member;
        this._validationData.validationStatus = member
          ? ValidationStatus.MemberChosen
          : ValidationStatus.NeedsValidation;

        this.call.memberId = this.confirmedMember
          ? this.confirmedMember.memberId
          : null;
        this.callsApiService.updateCall(this.call).subscribe(
          (res) => {
            this.openAccordionByCallState();
          },
          (error) => {
            this.sharedStore.dispatch(
              new fromSharedActions.SetCurrentError(error)
            );
          }
        );
      });
  }

  onSelectedCaseChange(memberCase: MemberCase | null) {
    if (
      this.selectedCase &&
      memberCase &&
      this.selectedCase.memberCaseId === memberCase.memberCaseId
    ) {
      return;
    }
    timer() // timer for expression changed after checked errors
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.selectedCase = memberCase;
        this._validationData.memberCase = memberCase;
        this._validationData.validationStatus = memberCase
          ? ValidationStatus.CaseChosen
          : ValidationStatus.MemberChosen;
        this.call.memberCaseId = this.selectedCase
          ? this.selectedCase.memberCaseId
          : null;

        this.callsApiService.updateCall(this.call).subscribe(
          (res) => {
            this.openAccordionByCallState();
          },
          (error) => {
            this.sharedStore.dispatch(
              new fromSharedActions.SetCurrentError(error)
            );
          }
        );
      });
  }

  private openAccordionByCallState(panelDelayMs: number = 300): void {
    if (!this._validationData) {
      return;
    }

    if (
      this._validationData.callStatus === CallStatus.Inbound &&
      this._validationData.validationStatus === ValidationStatus.NeedsValidation
    ) {
      timer(panelDelayMs).subscribe((_) => this.memberPanel.open());
      this.casePanel.close();
    } else if (
      this._validationData.callStatus === CallStatus.Inbound &&
      this._validationData.validationStatus === ValidationStatus.MemberChosen &&
      this.confirmedMember == null
    ) {
      this.memberPanel.close();
      timer(panelDelayMs).subscribe((_) => this.memberPreviewPanel.open());
    } else if (
      this._validationData.callStatus === CallStatus.Inbound &&
      this._validationData.validationStatus === ValidationStatus.MemberChosen
    ) {
      this.memberPanel.close();
      timer(panelDelayMs).subscribe((_) => this.casePanel.open());
    } else if (
      (this._validationData.callStatus === CallStatus.Inbound &&
        this._validationData.validationStatus ===
          ValidationStatus.CaseChosen) ||
      (this._validationData.callStatus === CallStatus.AwaitingCall &&
        this._validationData.validationStatus === ValidationStatus.AwaitingCall)
    ) {
      this.memberPanel.close();
      this.memberPreviewPanel.close();
      this.casePanel.close();
    }
  }

  onClickSwapContext(): void {
    this.router.navigate(['/call-context-swap']);
  }

  onClickResetCallInfo(): void {
    if (!this.phoneNumber || !this.call) {
      this.clearValidationState();
      this._validationData.callStatus = CallStatus.AwaitingCall;
      this._validationData.validationStatus = ValidationStatus.AwaitingCall;
      return;
    }

    this.clearValidationState(true);
    this.phoneNumberFormatted = this.phonePipe.transform(this.phoneNumber);
    this.call.callPartyPhone = this.phoneNumber;
    this.call.directionId = Direction.inbound;
    this.call.memberId = null;
    this.call.memberCaseId = null;

    this.callsApiService.updateCall(this.call).subscribe(
      (res) => {
        // Update our call so as to have the new callId for further updates
        this.call = res;
        // Update local component state information.
        this._validationData.phoneNumber = res.callPartyPhone;
        this._validationData.contactId = res.contactId;
        this._validationData.callStatus = CallStatus.Inbound;
        this._validationData.validationStatus =
          ValidationStatus.NeedsValidation;
        this._validationData.member = null;
        this._validationData.memberCase = null;
        this.openAccordionByCallState();
      },
      (error) => {
        this.sharedStore.dispatch(new fromSharedActions.SetCurrentError(error));
      }
    );
  }

  protected onDestroy(): void {
    this.sharedStore.dispatch(
      new fromSharedActions.SetHeaderOptions({
        iconNavigationLink: 'member-services-mgmt/dashboard',
        shouldShowHelp: true,
        shouldShowImpersonator: true,
        shouldShowModules: true,
        shouldShowNotifications: true,
        shouldShowSearch: true,
      })
    );
  }
}
