import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Provider } from 'carehub-api/models/provider/provider';
import { ProvidersApiService } from 'carehub-api/providers-api.service';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { Guid } from 'carehub-shared/guid';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

@Component({
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent extends BaseComponent implements OnInit {
  provider$: Observable<Provider>;
  menuItems: { displayName: string; routeName: string }[] = [
    {
      displayName: 'DETAILS',
      routeName: 'details',
    },
    {
      displayName: 'PROF DETAILS',
      routeName: 'professional',
    },
    {
      displayName: 'DOCUMENTS',
      routeName: 'documents',
    },
    {
      displayName: 'PROCEDURES',
      routeName: 'procedures',
    },
    {
      displayName: 'FACILITIES',
      routeName: 'facilities',
    },
    {
      displayName: 'CLINICS',
      routeName: 'clinics',
    },
    {
      displayName: 'GROUPS',
      routeName: 'groups',
    },
    {
      displayName: 'CONTRACTS',
      routeName: 'contracts',
    },
    {
      displayName: 'HISTORY',
      routeName: 'history',
    },
  ];

  constructor(
    private service: ProvidersApiService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.provider$ = this.route.params.pipe(
      takeUntil(this.unsubscribe$),
      map((snapshot) => snapshot['id']),
      filter((providerId) => providerId !== Guid.empty),
      distinctUntilChanged(),
      switchMap((providerId) => this.service.getProviderById(providerId)),
      takeUntil(this.unsubscribe$)
    );
  }

  protected onDestroy(): void {}
}
