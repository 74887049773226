import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ch-side-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() menuItems: {
    displayName: string;
    routeName: string;
    disabled: () => boolean;
  }[];
  constructor() {}

  ngOnInit() {}

  getClasses(menuItem: any): Object {
    return {
      'btn-disabled': menuItem.disabled ? menuItem.disabled() : false,
    };
  }
}
