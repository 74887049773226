import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractsApiService } from 'carehub-api/contracts-api.service';
import { Contract } from 'carehub-api/models/contract/contract';
import { Guid } from 'carehub-shared/guid';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit {
  contract$: Observable<Contract>;

  menuItems: { displayName: string; routeName: string }[] = [
    {
      displayName: 'DETAILS',
      routeName: 'details',
    },
    {
      displayName: 'COMPONENTS',
      routeName: 'components',
    },
    {
      displayName: 'PROCEDURES',
      routeName: 'procedures',
    },
    {
      displayName: 'CONTACTS',
      routeName: 'contacts',
    },
    {
      displayName: 'DOCUMENTS',
      routeName: 'documents',
    },
    {
      displayName: 'HISTORY',
      routeName: 'history',
    },
  ];

  constructor(
    public route: ActivatedRoute,
    private service: ContractsApiService
  ) {}

  ngOnInit() {
    var contractId = this.route.snapshot.params.id;
    if (contractId && contractId !== Guid.empty) {
      this.contract$ = this.service.getContractById(contractId);
    }
  }
}
