import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DemographicType } from 'carehub-api/enums/member/demographictype';
import { MemberCasesApiService } from 'carehub-api/membercases-api.service';
import { Demographic } from 'carehub-api/models/member/demographic';
import { ProviderPlacement } from 'carehub-api/models/membercase/providerplacement';
import { environment } from 'carehub-root/../environments/environment';
import { LookupService } from 'carehub-root/shared/services/lookup.service';
import { LookupTypes } from 'carehub-root/shared/services/models/lookup-types.enum';
import * as fromRoot from 'carehub-root/state/app.state';
import { SmartListResult } from 'carehub-shared/smartlist';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { GeneratedDocumentDetails } from '../../document-gen-dialog.component';

@Component({
  selector: 'ch-blank-referral-form-maker',
  templateUrl: './blank-referral-form-maker.component.html',
})
export class BlankReferralFormMakerComponent implements OnInit {
  private _documentDetails: GeneratedDocumentDetails;
  @Input() set documentDetails(value: GeneratedDocumentDetails) {
    this._documentDetails = value;
    if (this._documentDetails != null) {
      if (this.documentDetails.memberCase.requestedProcedureItemCategoryId) {
        this.procedureName$ = this.lookupService.getDescriptionByTypeAndId(
          LookupTypes.ProcedureItemCategories,
          this.documentDetails.memberCase.requestedProcedureItemCategoryId
        );
      } else {
        this.sharedStore.dispatch(
          new sharedActions.SetCurrentError(
            'No Procedure Category has been selected on the Case Header.'
          )
        );
      }

      this.providerName$ = this.memberCaseService
        .getProviderRecsByCaseId(this.documentDetails.memberCase.memberCaseId)
        .pipe(
          map((ProviderPlacement: SmartListResult<ProviderPlacement>) => {
            const finalizedProviderRecs = ProviderPlacement.results.filter(
              (c) => c.finalizedDate
            );
            let found = false;
            if (finalizedProviderRecs && finalizedProviderRecs.length > 0) {
              const providerRec = finalizedProviderRecs[0].options.find(
                (c) =>
                  c.providerRecOptionId ===
                  finalizedProviderRecs[0].selectedProviderRecOptionId
              );
              if (providerRec) {
                found = true;
                return `Dr. ${providerRec.firstName} ${providerRec.lastName}`;
              }
            }
            if (!found) {
              this.sharedStore.dispatch(
                new sharedActions.SetCurrentError(
                  'No Provider has been finalized for this Case.'
                )
              );
              return '';
            }
          })
        );
    }
  }
  get documentDetails(): GeneratedDocumentDetails {
    return this._documentDetails;
  }

  providerName$: Observable<string>;
  procedureName$: Observable<string>;
  stylesheetUrl: SafeStyle;
  logoUrl: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private lookupService: LookupService,
    private sharedStore: Store<fromRoot.State>,
    private memberCaseService: MemberCasesApiService
  ) {
    const stylesheetUrl =
      environment.blobStorage + '/html2pdf/blank-referral-form.css';
    this.stylesheetUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(stylesheetUrl);
    const logoUrl = environment.blobStorage + '/html2pdf/surgery-plus.png';
    this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(logoUrl);
  }

  ngOnInit() {}

  get blobStorageRoot() {
    return environment.blobStorage;
  }

  get phone(): string {
    const demographic = this.getDemographic();
    if (demographic) {
      return demographic.phoneExtension
        ? `${demographic.phone}x${demographic.phoneExtension}`
        : demographic.phone;
    } else {
      return '';
    }
  }

  get memberName(): string {
    return `${this.documentDetails.member.firstName} ${this.documentDetails.member.lastName}`;
  }

  get careAdvocateName(): string {
    return `${this.documentDetails.currentUser.firstName} ${this.documentDetails.currentUser.lastName}`;
  }

  get hubEmail(): string {
    if (
      this.documentDetails &&
      this.documentDetails.teamUsers &&
      this.documentDetails.teamUsers.length > 0
    ) {
      const team = this.documentDetails.teamUsers[0];
      return `${team.team.email}`;
    } else {
      return '';
    }
  }

  get hubPhoneNumber(): string {
    if (
      this.documentDetails &&
      this.documentDetails.teamUsers &&
      this.documentDetails.teamUsers.length > 0
    ) {
      const team = this.documentDetails.teamUsers[0];
      return `${team.team.phone}`;
    } else {
      return '';
    }
  }

  get address$(): Observable<string> {
    const demographic = this.getDemographic();
    if (demographic) {
      return this.lookupService
        .getDescriptionByTypeAndId(LookupTypes.States, demographic.stateId)
        .pipe(
          first(),
          map((state) => {
            const buffer = [];
            buffer.push(demographic.address1);
            if (
              demographic.address2 &&
              demographic.address2.trim().length > 0
            ) {
              buffer.push(', ');
              buffer.push(demographic.address2);
            }
            buffer.push(', ');
            buffer.push(demographic.city);
            buffer.push(', ');
            buffer.push(state);
            buffer.push(` ${demographic.zip}`);

            return buffer.join('');
          })
        );
    } else {
      return of('');
    }
  }

  get email(): string {
    const demographic = this.getDemographic();
    if (demographic) {
      return demographic.email;
    } else {
      return '';
    }
  }

  private getDemographic(): Demographic {
    let demographic: Demographic;
    if (
      this.documentDetails &&
      this.documentDetails.demographics &&
      this.documentDetails.demographics.length > 0
    ) {
      demographic = this.documentDetails.demographics.find(
        (c) => c.demographicTypeId === <number>DemographicType.alternate
      );
      if (!demographic) {
        demographic = this.documentDetails.demographics[0];
      }
    }

    return demographic;
  }
}
